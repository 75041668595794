import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${props => props.theme.containerWidth.sm};

  & .grid-column {
    display: flex;
    flex-flow: column;
    margin-bottom: 1em;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  & h2 {
    text-transform: uppercase;
  }

  & p {
    margin-bottom: 1em;
    line-height: 1.24;
    text-align: left;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    span {
      font-weight: 800;
    }
  }

  ${up('tablet')} {
    max-width: ${props => props.theme.containerWidth.lg};
    margin-top: 16rem;
    margin-bottom: 10rem;
    text-align: left;
    align-items: flex-start;

    & .grid-column {
      margin-bottom: 0;
    }
  }

  ${up('desktop')} {
    margin-top: 18rem;
    margin-bottom: 12rem;

    & p {
      font-size: 2.1rem;
    }
  }
  
  ${up('largeDesktop')} {
    margin-top: 23rem;
    margin-bottom: 25rem;

    & p {
      font-size: 4.8rem;
    }
    
    .grid-row:nth-child(1) {
      margin-bottom: 15rem!important;
    }
  }
`

export default Wrapper
