import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
//@ts-ignore
import LogoWhite from 'src/static/icons/LogoWhite.inline.svg'
//@ts-ignore
import DownloadLabel from 'src/static/icons/DownloadLabel.inline.svg'
import Wrapper from './style'

interface Props {
  presentation: {
    url: string
  }
}

const DownloadPresentation: React.FC<Props> = props => {
  const url = props.presentation.url

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={0} lgMarginBottom={90}>
          <Col xs={12} sm={6} lg={4} smOffset={3} lgOffset={4}>
            <div className="presentation-container">
              <div>
                <LogoWhite />
              </div>
              <a href={url} target="_blank" rel="nofollow noopener noreferrer" >
                <DownloadLabel />
              </a>
            </div>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default DownloadPresentation
