import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import H2 from 'src/components/Typography/H2'
import H5 from 'src/components/Typography/H5'
import Caption from 'src/components/Typography/Caption'
import Wrapper from './style'

const ExpositionContacts: React.FC = () => {

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={40} lgMarginBottom={80}>
          <Col xs={12} lg={4} lgOffset={1}>
            <H2>СОТРУДНИЧЕСТВО</H2>
          </Col>
        </Row>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={4} lgOffset={2} className="grid-column about">
            <div>
              <H5>Галерея современного искусства PS Gallery создает интересные
                   и&nbsp;качественные арт-проекты. Мы открыты к&nbsp;сотрудничеству
                   по проведению выставок во всех городах мира. Свяжитесь с&nbsp;нами,
                   если Вам нужна необычная и&nbsp;ценная экспозиция
              </H5>
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <div className="column-wrapper middle">
              <Caption>{`По вопросам <br/>сотрудничества:`}</Caption>
              <p className="phone"><a href="tel:+78129411009">+7 (812) 941-10-09</a></p>
              <p className="mail"><a href="mailto:psgallery@yandex.ru">psgallery@yandex.ru</a></p>
              <p className="phone"><a href="tel:+79219411009">+7 (921) 941-10-09</a></p>
              <p><span>Павел Башмаков, <br/>владелец галереи</span></p>
            </div>
          </Col>
          <Col xs={12} lg={2}>
            <div className="column-wrapper">
              <Caption>{`По вопросам <br/>проведения выставок:`}</Caption>
              <p className="phone"><a href="tel:+79281126392">+7 (928) 112-63-92</a></p>
              <p className="mail">
                <a href="mailto:yana.v.kirillova@gmail.com">yana.v.kirillova@gmail.com</a>
              </p>
              <p><span>Яна Кириллова, <br/>арт-директор</span></p>
            </div>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default ExpositionContacts
