import React from 'react'
import { Grid, Row, Col } from '@baevra/grid'
import H2 from 'src/components/Typography/H2'
import Wrapper from './style'

const AboutGalley: React.FC = () => {

  return (
    <Wrapper>
      <Grid xsColumnGap={0} lgColumnGap={20}>
        <Row xsMarginBottom={40} lgMarginBottom={80}>
          <Col xs={12} lg={4} lgOffset={1}>
            <H2>О галерее</H2>
          </Col>
        </Row>
        <Row xsMarginBottom={0}>
          <Col xs={12} lg={4} lgOffset={2}>
            <p>Галерея современного искусства <span>PS&nbsp;Gallery</span> была основана
              в&nbsp;2010&nbsp;году в&nbsp;Санкт-Петербурге.</p>

              <p>Основными задачами галереи являются популяризация
              в&nbsp;России европейской традиции коллекционирования
               оригинальных предметов искусства,
              проведение выставок, семинаров, образовательных мероприятий.</p>

              <p>На данный момент в&nbsp;галерее PS&nbsp;Gallery представлена крупнейшая
              в&nbsp;России коллекция оригинальной
               графики <span>Сальвадора Дали</span>. Галерея является
              организатором эксклюзивных проектов высокой художественной ценности
              в&nbsp;различных городах России. Выставки Сальвадора Дали от&nbsp;PS&nbsp;Gallery
              становятся самыми посещаемыми музейными проектами в&nbsp;регионах.</p>
          </Col>
          <Col xs={12} lg={4} lgOffset={1}>
            <p>Выставки PS&nbsp;Gallery включают в&nbsp;себя подлинники произведений
              искусства, находящиеся в&nbsp;собственности галереи
              и&nbsp;<span>Павла Анатольевича Башмакова</span>, а&nbsp;также экспонаты
              из&nbsp;собрания известнейшего российского коллекционера
              &nbsp;<span>Марка Ивановича Башмакова</span>.</p>

              <p><span>Марк Иванович Башмаков</span>&nbsp;— математик,
               ученый-педагог, доктор физико-математических наук,
                профессор, действительный член Российской академии образования,
                член Всемирного клуба петербуржцев, альпинист, коллекционер.
                Один из самых известных в&nbsp;России коллекционеров произведений искусства
                  парижской школы в&nbsp;жанре livre d’artiste (фр.&nbsp;«книга художника»).</p>

              <p>С&nbsp;2015&nbsp;года в&nbsp;Государственном
                 Эрмитаже на&nbsp;постоянной основе проводятся
                выставки экспонатов из собрания Башмакова,
                а&nbsp;в&nbsp;феврале 2017-го музей с&nbsp;благодарностью
                 принял 20 знаковых книг художника
                как дар коллекционера и&nbsp;включил их в&nbsp;свою постоянную экспозицию.</p>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

export default AboutGalley
