import React from 'react'
import { graphql } from 'gatsby'
import ProgressBar from 'src/components/ProgressBar'
import { PageRendererProps } from 'gatsby'
import Layout from 'src/layout'
import AboutGallery from 'src/sections/AboutGallery'
import ExpostionContacts from 'src/sections/ExpositionContacts'
import DownloadPresentation from 'src/sections/DownloadPresentation'
import SEO from 'src/components/SEO'
import sortBy from 'lodash/sortBy'

const createPresentation = (wordpressPresentation: any) => {
  return wordpressPresentation.edges
    .map((edge: any) => ({
      ...edge.node.acf.presentation.localFile,
      id: edge.node.wordpress_id
    }))
}

const AboutPage: React.FC<PageRendererProps> = (props: any) => {
  const presentations = createPresentation(props.data.allWordpressAcfPresentation)
  const sortedPresentations = sortBy(presentations, ['id'])

  const lastPresentation = sortedPresentations
    ? sortedPresentations[sortedPresentations.length - 1]
    : null

  // eslint-disable-next-line
  console.log(sortedPresentations, lastPresentation)

  return (
    <Layout>
      <SEO
        title="О галерее - PS Gallery"
        description={`
          Галерея современного искусства PS Gallery создает
          интересные и\xa0качественные арт-проекты. Мы открыты
          к\xa0сотрудничеству по проведению выставок во\xa0всех
          городах мира. Свяжитесь с\xa0нами, если Вам нужна
          необычная и\xa0ценная экспозиция
        `}
        url={props.location.href}
      />
      <ProgressBar />
      <main className="centered">
        <AboutGallery />
        {presentations && <DownloadPresentation presentation={lastPresentation} />}
        <ExpostionContacts />
      </main>
      <div className="bar" style={{ zIndex: -1 }} />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    allWordpressAcfPresentation {
      edges {
        node {
          acf {
            presentation {
              localFile {
                url
              }
            }
          }
          wordpress_id
        }
      }
    }
  }
`
