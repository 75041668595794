import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 7rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${props => props.theme.containerWidth.sm};

  & .grid-column {
    display: flex;
    flex-flow: column;
    margin-bottom: 4rem;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    & > .column-wrapper {
      display: flex;
      flex-flow: column;

      & > p + p {
        margin-top: .75em;
      }

      & > p.phone {
        margin-top: 3rem;
      }

      & p > span {
        color: ${props => props.theme.colors.subtitle};
      }
    }

    & h5 {
      text-align: left;
    }
  }

  & h2 {
    text-transform: uppercase;
  }

  ${up('tablet')} {
    max-width: ${props => props.theme.containerWidth.lg};
    margin-top: 0;
    margin-bottom: 10rem;
    text-align: left;
    align-items: flex-start;

    & .grid-column {
      margin-bottom: 0;
            
      &.about > div {
        padding-right: 20%;
      }

      & > .column-wrapper {
        &.middle {
          padding-left: 25%;
        }
      }
    }
  }

  ${up('desktop')} {
    margin-bottom: 12rem;

    & .grid-column > .column-wrapper {
      &.middle {
        padding-left: 33%;
      }
    }
  }
  
  ${up('largeDesktop')} {
    margin-bottom: 25rem;
    
    .grid-row:nth-child(1) {
      margin-bottom: 15rem!important;
    }
  }
`

export default Wrapper
