import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column;
  text-align: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${props => props.theme.containerWidth.sm};

  ${up('tablet')} {
    max-width: ${props => props.theme.containerWidth.lg};
  }

  .presentation-container {
    display: flex;
    flex-flow: column;
    position: relative;
    border: 1px solid ${props => props.theme.colors.separator[300]};
    margin-top: 5rem;

    & > div {
      background-color: ${props => props.theme.colors.backgroundDark};
      padding-top: 55%;
      position: relative;
      margin: -5rem 3% 0 3%;

      & > svg {
        position: absolute;
        display: flex;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28%;
        height: auto;
        object-fit: contain;
      }
    }

    & > a {
      display: inline-flex;
      width: 100%;
      max-width: 70vw;
      margin: 2rem auto;
      transition: opacity .3s ease-in-out;

      &:hover {
        opacity: .5;
      }

      & > svg {
        width: 100%;
        height: auto;
        object-fit: contain;
      }

      ${up('mobile')} {
        max-width: 50%;
      }

      ${up('tablet')} {
        margin: 3rem auto;
      } 

      ${up('desktop')} {
        margin: 5rem auto;
      }

      ${up('largeDesktop')} {
        margin: 10rem auto;
        min-width: 30%;
      }

    }
  }

`

export default Wrapper
